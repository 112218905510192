<template>
  <BaseTableLoader :loading="loading">
    <v-data-table
      :expanded="tableMixin_expandedRows"
      :footer-props="{
        'items-per-page-options': [pagination.rowsPerPage],
      }"
      :headers="tableMixin_displayedHeaders"
      :items="decoratedRows"
      :mobile-breakpoint="0"
      :page="pagination.page"
      :server-items-length="pagination.totalItems"
      :hide-default-header="displayAs === 'timeline'"
      disable-sort
      @click:row="$emit('click:row', $event)"
      @update:page="tableMixin_changePage"
    >
      <template v-if="displayAs === 'timeline'" v-slot:body="{ items }">
        <v-divider />
        <MachineryLoadTimeline :machineries="items" :key="pagination.page" />
      </template>

      <template v-slot:item.name="{ item }">
        <div class="link" @click="tableMixin_expandRow(item)">
          {{ item.name }}
        </div>
        <div class="grey--text">
          {{ item.reg_number }}
        </div>
      </template>

      <template v-slot:item.machinery_to_be_inspected_by="{ item }">
        <span v-if="item.responsible_user && item.responsible_user.person">{{
          item.responsible_user.person.full_name
        }}</span>
        <span class="text-no-wrap ml-1">{{ item.next_inspection_at }}</span>
      </template>

      <template v-slot:item.status="{ item }">
        <BaseChipSelect
          :value="item.status"
          :items="machineryStatuses"
          @change="onStatusChange(item, $event)"
        />
      </template>

      <template v-slot:item.processes="{ item }">
        <span v-for="(process, index) in item.processes" :key="process.id">
          <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="link">{{ process.title }}</span>
            </template>
            <ProcessCard :process-id="process.id" />
          </v-menu>
          <template v-if="index < item.processes.length - 1">, </template>
        </span>
      </template>

      <template v-slot:item.busy_until="{ item }">
        <span :class="{ 'grey--text': !item.busy_until }" class="text-no-wrap">
          {{ getMachineryBusiness(item.busy_until) }}
        </span>
      </template>

      <template v-slot:item.workHours="{ item }">
        <v-menu bottom offset-y left :close-on-content-click="false">
          <template #activator="{ on }">
            <div v-on="on" class="link">
              {{ item.workHours }}
            </div>
          </template>
          <v-simple-table dense class="py-1">
            <tbody>
              <tr
                v-for="weekday in weekdays"
                :key="weekday"
                :class="{ 'grey--text': !+item[`total_available_hours_on_${weekday}`] }"
              >
                <td>{{ $t(`machinery_hours_per_weekday.${weekday}`) }}:</td>
                <td>
                  {{ item[`total_available_hours_on_${weekday}`] }}
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-menu>
      </template>

      <template v-slot:item.actions="{ item }">
        <BaseActionMenu :actions="actions" :item="item" />
      </template>

      <template v-slot:expanded-item="{ item }">
        <BaseExpandedTableRow
          :colspan="tableMixin_displayedHeaders.length"
          :headers="tableMixin_hiddenHeaders"
          :item="item"
        >
          <template v-slot:item.processes>
            <span v-for="(process, index) in item.processes" :key="process.id">
              <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="link">{{ process.title }}</span>
                </template>
                <ProcessCard :process-id="process.id" />
              </v-menu>
              <template v-if="index < item.processes.length - 1">, </template>
            </span>
          </template>

          <template v-slot:item.status>
            <BaseChipSelect
              :value="item.status"
              :items="machineryStatuses"
              @change="onStatusChange(item, $event)"
            />
          </template>

          <template v-slot:item.workHours>
            <v-menu bottom offset-y left :close-on-content-click="false">
              <template #activator="{ on }">
                <div v-on="on" class="link">
                  {{ item.workHours }}
                </div>
              </template>
              <v-simple-table dense class="py-1">
                <tbody>
                  <tr
                    v-for="weekday in weekdays"
                    :key="weekday"
                    :class="{ 'grey--text': !+item[`total_available_hours_on_${weekday}`] }"
                  >
                    <td>{{ $t(`machinery_hours_per_weekday.${weekday}`) }}:</td>
                    <td>
                      {{ item[`total_available_hours_on_${weekday}`] }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </v-menu>
          </template>

          <template v-slot:item.busy_until>
            <span :class="{ 'grey--text': !item.busy_until }">
              {{ getMachineryBusiness(item.busy_until) }}
            </span>
          </template>

          <template v-slot:item.machinery_to_be_inspected_by>
            <span v-if="item.responsible_user && item.responsible_user.person">{{
              item.responsible_user.person.full_name
            }}</span>
            <span class="text-no-wrap ml-1">{{ item.next_inspection_at }}</span>
          </template>

          <template v-slot:append>
            <slot name="expanded-item-append" :machinery="item" />
          </template>
        </BaseExpandedTableRow>
      </template>
    </v-data-table>
  </BaseTableLoader>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import BaseTableLoader from '../base/BaseTableLoader';
import tableMixin from '../../mixins/table-mixin';
import BaseChipSelect from '../base/BaseChipSelect';
import { MACHINERY_STATUSES } from '../../api/machinery-service';
import { getTimeFromDate } from '../../util/date';
import { filters } from '../../util/filters';
import ProcessCard from '../ProcessCard';
import MachineryLoadTimeline from '../MachineryLoadTimeline';

export default {
  name: 'MachineryTable',

  components: {
    MachineryLoadTimeline,
    ProcessCard,
    BaseChipSelect,
    BaseTableLoader,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  props: {
    rows: Array,
    pagination: Object,
    loading: {
      type: Boolean,
      default: false,
    },
    displayAs: {
      type: String,
      default: 'table',
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('edit', p),
          label: this.$t('edit'),
          icon: 'mdi-pencil',
        },
        {
          callback: p => this.$emit('delete', p),
          label: this.$t('delete'),
          icon: 'mdi-delete',
        },
      ],
      headers: [
        {
          text: this.$t('name'),
          value: 'name',
        },
        {
          text: this.$t('processes'),
          value: 'processes',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('machinery_to_be_inspected_by'),
          value: 'machinery_to_be_inspected_by',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('status'),
          value: 'status',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('hour_price_wo_vat'),
          value: 'hourPriceWoVat',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('hour_employee_pay_wo_vat'),
          value: 'hourEmployeePayWoVat',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('marked_as_malfunction_at'),
          value: 'markedAsMalfunctionAt',
          hidden: 'smAndDown',
          align: 'end',
        },
        {
          text: this.$t('machinery_operating_time'),
          value: 'workHours',
          hidden: 'mdAndDown',
          align: 'end',
        },
        {
          text: this.$t('busy_until'),
          value: 'busy_until',
          hidden: 'mdAndDown',
          align: 'end',
        },
        {
          value: 'actions',
        },
      ],
      weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
    };
  },

  computed: {
    machineryStatuses() {
      return MACHINERY_STATUSES.map(s => ({ ...s, text: this.$t(s.text) }));
    },

    decoratedRows() {
      return this.rows.map(r => ({
        ...r,
        markedAsMalfunctionAt: r.marked_as_malfunction_at
          ? getTimeFromDate(r.marked_as_malfunction_at)
          : '',
        hourPriceWoVat: filters.currency(r.hour_price_wo_vat),
        hourEmployeePayWoVat: filters.currency(r.hour_employee_pay_wo_vat),
        workHours: this.getWorkHourCountPerWeekString(r),
      }));
    },
  },

  methods: {
    getMachineryBusiness(busyUntil) {
      if (!busyUntil) {
        return this.$t('machinery_not_busy');
      }
      return busyUntil;
    },

    getWorkHourCountPerWeekString(machinery) {
      return `${this.weekdays.reduce(
        (hours, day) => hours + +machinery[`total_available_hours_on_${day}`],
        0,
      )} ${this.$t('hours_per_week_short')}`;
    },

    onRowClick(item) {
      this.tableMixin_expandRow(item);
      this.$emit('click:row', item);
    },

    onStatusChange(item, newStatus) {
      const updatedMachinery = {
        ...item,
        status: newStatus,
      };
      this.$emit('update:machinery', updatedMachinery);
    },
  },
};
</script>
