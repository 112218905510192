<template>
  <v-data-table
    :expanded="tableMixin_expandedRows"
    :headers="tableMixin_displayedHeaders"
    :items="rows"
    :mobile-breakpoint="0"
    class="not-editable-table"
    dense
    disable-sort
    disable-pagination
    hide-default-footer
    @click:row="tableMixin_onRowClick"
  >
    <template v-slot:item.title="{ item }">
      <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
        <template v-slot:activator="{ on }">
          <span v-on="on" class="link">
            {{ item.title }}
          </span>
        </template>
        <ProcessCard :process-id="item.id" />
      </v-menu>
    </template>

    <template v-slot:item.pivot.hour_price_wo_vat="{ item }">
      <BaseEditableField
        v-model.number="item.pivot.hour_price_wo_vat"
        :has-error="!item.pivot.hour_price_wo_vat && item.pivot.hour_price_wo_vat !== 0"
        type="number"
        @input="
          $emit('clear:errors', `processes.${itemIndexesMap[item.id]}.pivot.hour_price_wo_vat`)
        "
      />
    </template>

    <template v-slot:item.pivot.hour_employee_pay_wo_vat="{ item }">
      <BaseEditableField
        v-model.number="item.pivot.hour_employee_pay_wo_vat"
        :has-error="!item.pivot.hour_price_wo_vat && item.pivot.hour_employee_pay_wo_vat !== 0"
        type="number"
        @input="
          $emit(
            'clear:errors',
            `processes.${itemIndexesMap[item.id]}.pivot.hour_employee_pay_wo_vat`,
          )
        "
      />
    </template>

    <template v-slot:item.actions="{ item }">
      <BaseActionMenu :actions="actions" :item="item" />
    </template>

    <template v-slot:expanded-item="{ item, index }">
      <BaseExpandedTableRow
        :colspan="tableMixin_displayedHeaders.length"
        :headers="tableMixin_hiddenHeaders"
        :item="item"
      >
        <template v-slot:item.pivot.hour_price_wo_vat>
          <BaseEditableField
            v-model.number="item.pivot.hour_price_wo_vat"
            :has-error="!item.pivot.hour_price_wo_vat && item.pivot.hour_price_wo_vat !== 0"
            type="number"
            @input="
              $emit('clear:errors', `processes.${itemIndexesMap[item.id]}.pivot.hour_price_wo_vat`)
            "
          />
        </template>

        <template v-slot:item.pivot.hour_employee_pay_wo_vat>
          <BaseEditableField
            v-model.number="item.pivot.hour_employee_pay_wo_vat"
            :has-error="!item.pivot.hour_price_wo_vat && item.pivot.hour_employee_pay_wo_vat !== 0"
            type="number"
            @input="
              $emit(
                'clear:errors',
                `processes.${itemIndexesMap[item.id]}.pivot.hour_employee_pay_wo_vat`,
              )
            "
          />
        </template>
      </BaseExpandedTableRow>
    </template>
  </v-data-table>
</template>

<script>
import BaseActionMenu from '../base/BaseActionMenu';
import BaseExpandedTableRow from '../base/BaseExpandedTableRow';
import tableMixin from '../../mixins/table-mixin';
import ProcessCard from '../ProcessCard';
import BaseEditableField from '../base/BaseEditableField';

export default {
  name: 'MachineryProcessTable',

  components: {
    BaseEditableField,
    ProcessCard,
    BaseExpandedTableRow,
    BaseActionMenu,
  },

  mixins: [tableMixin],

  model: {
    prop: 'rows',
    event: 'change',
  },

  props: {
    rows: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      actions: [
        {
          callback: p => this.$emit('remove', p),
          label: this.$t('remove'),
          icon: 'mdi-close',
        },
      ],
    };
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('hour_price_wo_vat'),
          value: 'pivot.hour_price_wo_vat',
          hidden: 'xsOnly',
        },
        {
          text: this.$t('hour_employee_pay_wo_vat'),
          value: 'pivot.hour_employee_pay_wo_vat',
          hidden: 'xsOnly',
        },
        {
          value: 'actions',
        },
      ];
    },

    itemIndexesMap() {
      // didn't want to create a new array to keep the binding with prop,
      // but I needed index field in column slots
      const map = {};
      for (let i = 0; i < this.rows.length; i++) {
        map[this.rows[i].id] = i;
      }
      return map;
    },
  },
};
</script>
