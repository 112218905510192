var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTableLoader',{attrs:{"loading":_vm.loading}},[_c('v-data-table',{attrs:{"expanded":_vm.tableMixin_expandedRows,"footer-props":{
      'items-per-page-options': [_vm.pagination.rowsPerPage],
    },"headers":_vm.tableMixin_displayedHeaders,"items":_vm.decoratedRows,"mobile-breakpoint":0,"page":_vm.pagination.page,"server-items-length":_vm.pagination.totalItems,"hide-default-header":_vm.displayAs === 'timeline',"disable-sort":""},on:{"click:row":function($event){return _vm.$emit('click:row', $event)},"update:page":_vm.tableMixin_changePage},scopedSlots:_vm._u([(_vm.displayAs === 'timeline')?{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('v-divider'),_c('MachineryLoadTimeline',{key:_vm.pagination.page,attrs:{"machineries":items}})]}}:null,{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"link",on:{"click":function($event){return _vm.tableMixin_expandRow(item)}}},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(item.reg_number)+" ")])]}},{key:"item.machinery_to_be_inspected_by",fn:function(ref){
    var item = ref.item;
return [(item.responsible_user && item.responsible_user.person)?_c('span',[_vm._v(_vm._s(item.responsible_user.person.full_name))]):_vm._e(),_c('span',{staticClass:"text-no-wrap ml-1"},[_vm._v(_vm._s(item.next_inspection_at))])]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.machineryStatuses},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}})]}},{key:"item.processes",fn:function(ref){
    var item = ref.item;
return _vm._l((item.processes),function(process,index){return _c('span',{key:process.id},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(_vm._s(process.title))])]}}],null,true)},[_c('ProcessCard',{attrs:{"process-id":process.id}})],1),(index < item.processes.length - 1)?[_vm._v(", ")]:_vm._e()],2)})}},{key:"item.busy_until",fn:function(ref){
    var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap",class:{ 'grey--text': !item.busy_until }},[_vm._v(" "+_vm._s(_vm.getMachineryBusiness(item.busy_until))+" ")])]}},{key:"item.workHours",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.workHours)+" ")])]}}],null,true)},[_c('v-simple-table',{staticClass:"py-1",attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.weekdays),function(weekday){return _c('tr',{key:weekday,class:{ 'grey--text': !+item[("total_available_hours_on_" + weekday)] }},[_c('td',[_vm._v(_vm._s(_vm.$t(("machinery_hours_per_weekday." + weekday)))+":")]),_c('td',[_vm._v(" "+_vm._s(item[("total_available_hours_on_" + weekday)])+" ")])])}),0)])],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.actions,"item":item}})]}},{key:"expanded-item",fn:function(ref){
    var item = ref.item;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.processes",fn:function(){return _vm._l((item.processes),function(process,index){return _c('span',{key:process.id},[_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(_vm._s(process.title))])]}}],null,true)},[_c('ProcessCard',{attrs:{"process-id":process.id}})],1),(index < item.processes.length - 1)?[_vm._v(", ")]:_vm._e()],2)})},proxy:true},{key:"item.status",fn:function(){return [_c('BaseChipSelect',{attrs:{"value":item.status,"items":_vm.machineryStatuses},on:{"change":function($event){return _vm.onStatusChange(item, $event)}}})]},proxy:true},{key:"item.workHours",fn:function(){return [_c('v-menu',{attrs:{"bottom":"","offset-y":"","left":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('div',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.workHours)+" ")])]}}],null,true)},[_c('v-simple-table',{staticClass:"py-1",attrs:{"dense":""}},[_c('tbody',_vm._l((_vm.weekdays),function(weekday){return _c('tr',{key:weekday,class:{ 'grey--text': !+item[("total_available_hours_on_" + weekday)] }},[_c('td',[_vm._v(_vm._s(_vm.$t(("machinery_hours_per_weekday." + weekday)))+":")]),_c('td',[_vm._v(" "+_vm._s(item[("total_available_hours_on_" + weekday)])+" ")])])}),0)])],1)]},proxy:true},{key:"item.busy_until",fn:function(){return [_c('span',{class:{ 'grey--text': !item.busy_until }},[_vm._v(" "+_vm._s(_vm.getMachineryBusiness(item.busy_until))+" ")])]},proxy:true},{key:"item.machinery_to_be_inspected_by",fn:function(){return [(item.responsible_user && item.responsible_user.person)?_c('span',[_vm._v(_vm._s(item.responsible_user.person.full_name))]):_vm._e(),_c('span',{staticClass:"text-no-wrap ml-1"},[_vm._v(_vm._s(item.next_inspection_at))])]},proxy:true},{key:"append",fn:function(){return [_vm._t("expanded-item-append",null,{"machinery":item})]},proxy:true}],null,true)})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }