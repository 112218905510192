<template>
  <v-form @submit.prevent="onSubmit">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="machinery.name"
              :error-messages="errors['name']"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              @blur="formMixin_clearErrors('name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="machinery.reg_number"
              :error-messages="errors['reg_number']"
              :label="$t('reg_number')"
              @blur="formMixin_clearErrors('reg_number')"
            />
          </v-col>

          <v-col cols="12" class="d-flex align-center justify-space-between">
            <h2 class="subtitle-1 font-weight-medium">
              {{ $t('processes') }}
            </h2>

            <ProcessSelectMenu
              :process-groups="notSelectedProcessGroups"
              left
              @select="addProcess"
            />
          </v-col>

          <v-col cols="12">
            <MachineryProcessTable
              v-if="machinery.processes.length"
              v-model="machinery.processes"
              @remove="removeProcess"
              @clear:errors="formMixin_clearErrors($event)"
            />
            <BaseFormErrors :error-messages="processesErrors" />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseDatepickerInput
              v-model="machinery.next_inspection_at"
              :error-messages="errors['next_inspection_at']"
              :label="$t('next_inspection_at')"
              @input="formMixin_clearErrors('next_inspection_at')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <BaseAutocomplete
              v-model="machinery.responsible_user_id"
              :search-function="userSearchFunction"
              :initial-item="machinery.responsible_user"
              :key="machinery.id"
              :error-messages="errors.responsible_user_id"
              :label="formMixin_getRequiredFieldLabel($t('responsible_user'))"
              item-text="full_name"
              @input="formMixin_clearErrors('responsible_user_id')"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12" sm="6">
            <v-radio-group
              v-model="machinery.status"
              :error-messages="errors['status']"
              class="mt-0"
              @change="formMixin_clearErrors('status')"
            >
              <v-radio
                v-for="status in machineryStatuses"
                :key="status.value"
                :value="status.value"
                :label="$t(status.text)"
              />
            </v-radio-group>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="12">
            <h2 class="title">
              {{ $t('machinery_operating_time') }}
            </h2>
          </v-col>

          <v-col v-for="weekday in weekdays" :key="weekday" cols="12" sm="6" md="4" lg="3">
            <v-text-field
              v-model.number="machinery[`total_available_hours_on_${weekday}`]"
              :error-messages="errors[`total_available_hours_on_${weekday}`]"
              :label="formMixin_getRequiredFieldLabel($t(`machinery_hours_per_weekday.${weekday}`))"
              type="number"
              @blur="formMixin_clearErrors(`total_available_hours_on_${weekday}`)"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!machinery.id" class="subtitle-2 ml-3"> * {{ $t('must_be_filled') }} </span>

        <v-spacer />

        <v-btn color="primary" text @click.native="$emit('cancel')">
          {{ $t('cancel') }}
        </v-btn>

        <v-btn
          :disabled="isRequestPending"
          :loading="isRequestPending"
          type="submit"
          color="primary"
          text
        >
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import crudMixin from '../../mixins/crud-mixin';
import dialogMixin from '../../mixins/dialog-mixin';
import formMixin from '../../mixins/form-mixin';
import machineryService, { MACHINERY_STATUSES } from '../../api/machinery-service';
import processGroupService from '../../api/process-group-service';
import processService from '../../api/process-service';
import ProcessSelectMenu from '../ProcessSelectMenu';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import userService from '@/api/user-service';
import MachineryProcessTable from '@/components/tables/MachineryProcessTable';
import BaseFormErrors from '@/components/base/BaseFormErrors';

export default {
  name: 'MachineryForm',

  components: {
    BaseFormErrors,
    MachineryProcessTable,
    BaseAutocomplete,
    BaseDatepickerInput,
    ProcessSelectMenu,
  },

  mixins: [crudMixin, dialogMixin, formMixin],

  props: {
    formItem: Object,
  },

  data() {
    return {
      errors: {},
      isRequestPending: false,
      machinery: {},
      processGroupArray: [],
      processSearchFunction: processService.search,
      machineryStatuses: MACHINERY_STATUSES,
      weekdays: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
      userSearchFunction: userService.search,
    };
  },

  computed: {
    formTitle() {
      return this.$t(this.machinery.id ? 'edit_machinery' : 'new_machinery');
    },

    notSelectedProcessGroups() {
      // filters out selected processes from processGroupArray
      const groups = [];
      for (let i = 0; i < this.processGroupArray.length; i++) {
        const group = this.processGroupArray[i];
        const notSelected = [];
        for (let j = 0; j < group.processes.length; j++) {
          const process = group.processes[j];
          if (this.machinery.processes.every(p => p.id !== process.id)) {
            notSelected.push(process);
          }
        }
        if (notSelected.length) {
          groups.push({
            ...group,
            processes: notSelected,
          });
        }
      }
      return groups;
    },

    processesErrors() {
      let errors = [];
      const errorKeys = Object.keys(this.errors);
      for (let i = 0; i < errorKeys.length; i++) {
        if (errorKeys[i].includes('processes')) {
          errors = [...errors, ...this.errors[errorKeys[i]]];
        }
      }
      return errors;
    },
  },

  created() {
    processGroupService.getAll().then(res => {
      this.processGroupArray = res.data;
    });
  },

  methods: {
    onDialogOpen() {
      this.machinery = JSON.parse(JSON.stringify(this.formItem));
      if (this.machinery.responsible_user) {
        this.machinery.responsible_user.full_name = this.machinery.responsible_user.person.full_name;
      }
      this.errors = {};
    },

    addProcess(process) {
      this.machinery.processes.push({
        id: process.id,
        title: process.title,
        pivot: {
          hour_employee_pay_wo_vat: 0,
          hour_price_wo_vat: 0,
        },
      });
    },

    removeProcess(process) {
      for (let i = 0; i < this.machinery.processes.length; i++) {
        if (this.machinery.processes[i].id === process.id) {
          this.machinery.processes.splice(i, 1);
          this.formMixin_clearErrors(`processes.${i}.pivot.hour_price_wo_vat`);
          this.formMixin_clearErrors(`processes.${i}.pivot.hour_employee_pay_wo_vat`);
          break;
        }
      }
    },

    setProcess(process) {
      this.formMixin_clearErrors('process_id');
      this.$set(this.machinery, 'process', process);
      this.machinery.process_id = process?.id;
    },

    onSubmit() {
      this.crudMixin_createOrUpdate(machineryService, this.machinery);
    },
  },
};
</script>
