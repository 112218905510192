<template>
  <BaseFilterDrawer :selected-filter-count="selectedFilterCount" @reset="filterMixin_resetFilter">
    <v-text-field
      :value="filters.search"
      :label="$t('search')"
      prepend-inner-icon="mdi-magnify"
      clearable
      @input="filterMixin_handleDebounce('search', $event)"
    />

    <BaseAutocomplete
      :value="filters.responsible_user_id"
      :search-function="searchUsers"
      :label="$t('responsible_user')"
      item-text="full_name"
      clearable
      @input="filterMixin_updateFilter('responsible_user_id', $event)"
    />

    <BaseDatepickerInput
      :value="filters.next_inspection_from"
      :label="$t('next_inspection_from')"
      clearable
      @input="filterMixin_updateFilter('next_inspection_from', $event)"
    />

    <BaseDatepickerInput
      :value="filters.next_inspection_to"
      :label="$t('next_inspection_to')"
      clearable
      @input="filterMixin_updateFilter('next_inspection_to', $event)"
    />

    <v-checkbox
      v-for="status in machineryStatuses"
      :key="status.value"
      :input-value="filters.status || []"
      :label="status.text"
      :value="status.value"
      hide-details
      @change="filterMixin_updateFilter('status', $event)"
    />
  </BaseFilterDrawer>
</template>

<script>
import BaseAutocomplete from '@/components/base/BaseAutocomplete';
import userService from '@/api/user-service';
import { MACHINERY_STATUSES } from '@/api/machinery-service';
import BaseDatepickerInput from '@/components/base/BaseDatepickerInput';
import filterMixin from '@/mixins/filter-mixin';
import BaseFilterDrawer from '@/components/filters/BaseFilterDrawer';

export default {
  name: 'MachineryFilter',

  components: { BaseFilterDrawer, BaseDatepickerInput, BaseAutocomplete },

  mixins: [filterMixin],

  computed: {
    machineryStatuses() {
      return MACHINERY_STATUSES.map(status => ({ ...status, text: this.$t(status.text) }));
    },
  },

  methods: {
    searchUsers: userService.search,
  },
};
</script>

<style scoped></style>
