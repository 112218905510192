import http from './http';
import getFormData from '@/util/form-data';

const endpoint = 'api/machinery-inspections';

const machineryInspectionService = {
  model: 'machineryInspection',

  getPage: (page, params) => http.get(endpoint, { params: { ...params, page } }),

  create: machineryInspection => http.post(`${endpoint}`, getFormData(machineryInspection)),

  update: machineryInspection => {
    const payload = getFormData(machineryInspection);
    payload.append('_method', 'PUT');
    return http.post(`${endpoint}/${machineryInspection.id}`, payload);
  },

  delete: machineryInspection => http.delete(`${endpoint}/${machineryInspection.id}`),
};

export default machineryInspectionService;
