<template>
  <div class="page-wrapper">
    <div class="d-flex justify-space-between px-4 pt-2 pb-4 flex-wrap">
      <div class="d-flex align-center py-2">
        <h1 class="title mr-3">
          {{ $t('machineries') }}
        </h1>
        <v-btn-toggle v-model="displayAs" color="primary" rounded dense mandatory>
          <v-btn value="table" color="white">
            <span>{{ $t('table') }}</span>
          </v-btn>

          <v-btn value="timeline" color="white">
            <span>{{ $t('chart') }}</span>
          </v-btn>
        </v-btn-toggle>
      </div>
      <div class="d-flex flex-wrap py-2 justify-end justify-sm-start">
        <BasePrimaryActionButton
          :label="$t('create_machinery')"
          @click="openMachineryForm(newMachineryTemplate)"
        />
        <MachineryFilter
          :filters="machineryFilterParams"
          class="mr-md-3 mb-3 mb-md-0"
          @change="updateFilter"
          @reset="updateFilter({})"
        />
        <v-btn color="primary" outlined @click="printMachineries">
          <v-icon left>mdi-printer</v-icon>
          {{ $t('print') }}
        </v-btn>
      </div>
    </div>

    <MachineryTable
      :loading="isDataLoading"
      :pagination="machineryPagination"
      :rows="decoratedMachineries"
      :display-as="displayAs"
      @change-page="getPaginatedMachineries"
      @edit="openMachineryForm"
      @delete="crudMixin_delete(onDelete, 'machinery', $event)"
      @click:row="expandMachinery"
      @update:machinery="updateMachinery"
    >
      <template #expanded-item-append>
        <MachineryInspections
          v-if="expandedMachinery.id"
          :key="`${expandedMachinery.id}-inspections`"
          :machinery-id="expandedMachinery.id || 0"
          class="mb-8"
        />

        <MachineryCommentList
          v-if="expandedMachinery.id"
          :key="`${expandedMachinery.id}-comments`"
          :machinery-id="expandedMachinery.id"
          :refresh-count="commentRefreshCount"
          :created-comment="createdComment"
          @new-item="crudMixin_openForm('machineryComment', newMachineryCommentTemplate)"
        />
      </template>
    </MachineryTable>

    <v-dialog
      :value="$store.getters.isDialogOpen('machineryForm')"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <MachineryForm
        :dialog="$store.getters.isDialogOpen('machineryForm')"
        :form-item="machineryFormItem"
        @create="crudMixin_created('machinery', $event)"
        @update="crudMixin_updated('machinery', $event)"
        @cancel="$store.commit('closeDialog', 'machineryForm')"
      />
    </v-dialog>

    <v-dialog
      v-model="isMachineryCommentFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <MachineryCommentForm
        :dialog="isMachineryCommentFormOpen"
        :form-item="machineryCommentFormItem"
        :title="machineryCommentFormTitle"
        @create="createdComment = $event"
        @cancel="closeMachineryCommentForm"
        @reset:title="machineryCommentFormTitle = ''"
      />
    </v-dialog>
  </div>
</template>

<script>
import { format } from 'date-fns';
import MachineryForm from '../components/forms/MachineryForm';
import MachineryTable from '../components/tables/MachineryTable';
import crudMixin from '../mixins/crud-mixin';
import machineryService from '../api/machinery-service';
import MachineryCommentList from '../components/MachineryCommentList';
import MachineryCommentForm from '../components/forms/MachineryCommentForm';
import { OPEN_DIALOG } from '../store/modules/dialogs';
import BasePrimaryActionButton from '../components/base/BasePrimaryActionButton';
import MachineryInspections from '@/views/MachineryInspections';
import MachineryFilter from '@/components/filters/MachineryFilter';

export default {
  name: 'Machineries',

  components: {
    MachineryFilter,
    MachineryInspections,
    BasePrimaryActionButton,
    MachineryCommentForm,
    MachineryCommentList,
    MachineryForm,
    MachineryTable,
  },

  mixins: [crudMixin],

  data() {
    return {
      commentRefreshCount: 0,
      createdComment: {},
      displayAs: 'table', // or 'timeline'
      machineryArray: [],
      machineryCommentFormItem: {},
      machineryCommentFormTitle: '',
      machineryFilterParams: {},
      machineryFormItem: {},
      machineryPagination: {
        page: 1,
      },
      expandedMachinery: {},
      newMachineryCommentTemplate: {},
      newMachineryTemplate: {
        status: 'operational',
        processes: [],
        total_available_hours_on_monday: 8,
        total_available_hours_on_tuesday: 8,
        total_available_hours_on_wednesday: 8,
        total_available_hours_on_thursday: 8,
        total_available_hours_on_friday: 8,
        total_available_hours_on_saturday: 0,
        total_available_hours_on_sunday: 0,
      },
      isMachineryCommentFormOpen: false,

      isDataLoading: true,
      onDelete: machineryService.delete,
    };
  },

  computed: {
    // busy_until field can be filled in backend even when it's '< now',
    // which screws up the load timeline and is in general incorrect
    decoratedMachineries() {
      const now = format(new Date(), 'yyyy-MM-dd');
      return this.machineryArray.map(m => ({
        ...m,
        busy_until: m.busy_until < now ? null : m.busy_until,
      }));
    },
  },

  created() {
    this.getPaginatedMachineries(1);
  },

  methods: {
    closeMachineryCommentForm() {
      this.isMachineryCommentFormOpen = false;
      this.machineryCommentFormTitle = '';
    },

    getPaginatedMachineries(pageNo) {
      this.crudMixin_getPage(
        machineryService.getPage,
        machineryService.model,
        pageNo,
        this.machineryFilterParams,
      );
    },

    printMachineries() {
      const url =
        window.location.origin +
        this.$router.resolve({
          name: 'machineries-print',
          query: this.machineryFilterParams,
        }).href;
      window.open(url, '_blank');
    },

    expandMachinery(machinery) {
      this.expandedMachinery = machinery;
      this.newMachineryCommentTemplate.machinery_id = machinery.id;
    },

    openMachineryForm(item) {
      this.machineryFormItem = JSON.parse(JSON.stringify(item));
      this.$store.commit(OPEN_DIALOG, 'machineryForm');
    },

    updateMachinery(updatedMachinery) {
      this.crudMixin_update(machineryService.update, machineryService.model, updatedMachinery).then(
        res => {
          this.crudMixin_updated('machinery', res.data);
          this.commentRefreshCount += 1;
          if (res.data.status === 'malfunction') {
            this.machineryCommentFormItem = {
              machinery_id: res.data.id,
            };
            this.machineryCommentFormTitle = `${this.$t('describe')} ${res.data.name} ${this.$t(
              'malfunction_accusative',
            ).toLowerCase()}`;
            this.isMachineryCommentFormOpen = true;
          }
        },
      );
    },

    updateFilter(filters) {
      this.machineryFilterParams = filters;
      this.getPaginatedMachineries(1);
    },
  },
};
</script>
