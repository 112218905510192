<template>
  <div class="px-4">
    <div
      v-for="machinery in decoratedMachineries"
      :key="machinery.id"
      class="d-flex flex-column flex-md-row py-2 body-2 mb-4 mb-md-0"
    >
      <div style="width: 12em;">
        {{ machinery.name }}<br />
        <span v-for="(process, index) in machinery.processes" :key="process.id">
          <v-menu :close-on-content-click="false" offset-y nudge-bottom="8">
            <template v-slot:activator="{ on }">
              <span v-on="on" class="link">{{ process.title }}</span>
            </template>
            <ProcessCard :process-id="process.id" />
          </v-menu>
          <template v-if="index < machinery.processes.length - 1">, </template>
        </span>
      </div>
      <div
        v-if="machinery.busy_until"
        class="flex-grow-1 d-flex align-center"
        style="min-height: 40px"
      >
        <div
          class="timeline-bar"
          :style="{
            width: drawBars ? machinery.width : '0',
          }"
        ></div>
        <v-tooltip bottom>
          <template #activator="{ on }">
            <v-scale-transition origin="center">
              <v-chip
                v-if="showCircles"
                v-on="on"
                :color="machineryStatusMap[machinery.status].color"
                small
              >
                {{ getFormattedDate(machinery.busy_until) }}
              </v-chip>
            </v-scale-transition>
          </template>
          <div>
            {{ $t('machinery_busy_until_long') }}
            {{ getFormattedDate(machinery.busy_until, 'MMMM dd') }} {{ $t('day_short') }}<br />
            {{ $t('machinery_status') }}:
            {{ $t(machineryStatusMap[machinery.status].text).toLowerCase() }}.
          </div>
        </v-tooltip>
      </div>
      <div v-else class="d-flex align-center grey--text">
        {{ $t('machinery_not_busy_long') }}
      </div>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import { lt } from 'date-fns/locale';
import ProcessCard from './ProcessCard';
import { getMachineryStatusMap } from '../api/machinery-service';

export default {
  name: 'MachineryLoadTimeline',

  components: { ProcessCard },

  props: {
    machineries: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      drawBars: false,
      showCircles: false,
      machineryStatusMap: getMachineryStatusMap(),
    };
  },

  computed: {
    decoratedMachineries() {
      if (!this.machineries.length) {
        return [];
      }

      // Find out the earliest date to display in the chart
      const timeNow = new Date(format(new Date(), 'yyyy-MM-dd')).getTime();

      // Find out the latest date to display in the chart
      let maxBusyUntil = this.machineries[0].busy_until;
      this.machineries.forEach(machinery => {
        if (machinery.busy_until > maxBusyUntil) {
          maxBusyUntil = machinery.busy_until;
        }
      });
      const maxDateTime = new Date(maxBusyUntil).getTime() - timeNow;

      // Calculate timeline bar width for each machinery using a proportion
      return this.machineries.map(machinery => {
        if (!machinery.busy_until) {
          return machinery;
        }
        const m = { ...machinery };
        const time = new Date(m.busy_until).getTime() - timeNow;
        const width = (time * 100) / maxDateTime;
        m.width = width ? `${width}%` : '12px';
        return m;
      });
    },
  },

  mounted() {
    setTimeout(() => {
      this.drawBars = true;
    });
    setTimeout(() => {
      this.showCircles = true;
    }, 500);
  },

  methods: {
    getFormattedDate(date, dateFormat = 'MM-dd') {
      return format(new Date(date), dateFormat, {
        locale: lt,
      });
    },
  },
};
</script>

<style scoped>
.timeline-bar {
  width: 0;
  height: 2px;
  transition: width, 0.5s ease;
  background-color: var(--v-secondary-lighten3);
}
</style>
