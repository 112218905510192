<template>
  <div>
    <div class="d-flex align-center justify-space-between pt-4">
      <h2 class="subtitle-2">
        {{ $t('machinery_inspections') }}
      </h2>

      <v-btn
        color="primary"
        @click="crudMixin_openForm('machineryInspection', newMachineryInspectionTemplate)"
      >
        <v-icon left>mdi-plus</v-icon>
        {{ $t('create_machinery_inspection') }}
      </v-btn>
    </div>

    <v-progress-circular v-if="isDataLoading" class="mt-2" color="primary" indeterminate />

    <MachineryInspectionTable
      v-else
      :rows="machineryInspectionArray"
      :pagination="machineryInspectionPagination"
      :loading="isDataLoading"
      :accepted-file-formats="acceptedFileFormats"
      @change-page="getMachineryInspections"
      @delete="deleteMachineryInspection"
      @edit="crudMixin_openForm('machineryInspection', $event)"
    />

    <v-dialog
      v-model="isMachineryInspectionFormOpen"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="slide-y-reverse-transition"
      max-width="800"
      persistent
      scrollable
    >
      <MachineryInspectionForm
        :dialog="isMachineryInspectionFormOpen"
        :form-item="machineryInspectionFormItem"
        :accepted-file-formats="acceptedFileFormats"
        @cancel="isMachineryInspectionFormOpen = false"
        @create="crudMixin_created('machineryInspection', $event)"
        @update="crudMixin_updated('machineryInspection', $event)"
      />
    </v-dialog>
  </div>
</template>

<script>
import crudMixin from '@/mixins/crud-mixin';
import MachineryInspectionTable from '@/components/tables/MachineryInspectionTable';
import machineryInspectionService from '@/api/machinery-inspection-service';
import MachineryInspectionForm from '@/components/forms/MachineryInspectionForm';

export default {
  name: 'MachineryInspections',

  components: { MachineryInspectionForm, MachineryInspectionTable },

  mixins: [crudMixin],

  props: {
    machineryId: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      acceptedFileFormats: '.jpeg, .jpg, .png, .gif, .svg, .pdf',
      machineryInspectionArray: [],
      machineryInspectionPagination: {},
      newMachineryInspectionTemplate: {},
      machineryInspectionFilterParams: {
        page_size: 25,
      },
      isDataLoading: true,

      isMachineryInspectionFormOpen: false,
      machineryInspectionFormItem: {},
    };
  },

  created() {
    if (this.machineryId) {
      this.newMachineryInspectionTemplate.machinery_id = this.machineryId;
      this.machineryInspectionFilterParams.machinery_id = this.machineryId;
    }
    this.getMachineryInspections(1);
  },

  methods: {
    getMachineryInspections(page) {
      this.crudMixin_getPage(
        machineryInspectionService.getPage,
        machineryInspectionService.model,
        page,
        this.machineryInspectionFilterParams,
        false,
        false,
      );
    },

    deleteMachineryInspection(item) {
      this.crudMixin_delete(
        machineryInspectionService.delete,
        machineryInspectionService.model,
        item,
      );
    },
  },
};
</script>

<style scoped></style>
