var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"not-editable-table",attrs:{"expanded":_vm.tableMixin_expandedRows,"headers":_vm.tableMixin_displayedHeaders,"items":_vm.rows,"mobile-breakpoint":0,"dense":"","disable-sort":"","disable-pagination":"","hide-default-footer":""},on:{"click:row":_vm.tableMixin_onRowClick},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":false,"offset-y":"","nudge-bottom":"8"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticClass:"link"},on),[_vm._v(" "+_vm._s(item.title)+" ")])]}}],null,true)},[_c('ProcessCard',{attrs:{"process-id":item.id}})],1)]}},{key:"item.pivot.hour_price_wo_vat",fn:function(ref){
var item = ref.item;
return [_c('BaseEditableField',{attrs:{"has-error":!item.pivot.hour_price_wo_vat && item.pivot.hour_price_wo_vat !== 0,"type":"number"},on:{"input":function($event){return _vm.$emit('clear:errors', ("processes." + (_vm.itemIndexesMap[item.id]) + ".pivot.hour_price_wo_vat"))}},model:{value:(item.pivot.hour_price_wo_vat),callback:function ($$v) {_vm.$set(item.pivot, "hour_price_wo_vat", _vm._n($$v))},expression:"item.pivot.hour_price_wo_vat"}})]}},{key:"item.pivot.hour_employee_pay_wo_vat",fn:function(ref){
var item = ref.item;
return [_c('BaseEditableField',{attrs:{"has-error":!item.pivot.hour_price_wo_vat && item.pivot.hour_employee_pay_wo_vat !== 0,"type":"number"},on:{"input":function($event){return _vm.$emit(
          'clear:errors',
          ("processes." + (_vm.itemIndexesMap[item.id]) + ".pivot.hour_employee_pay_wo_vat")
        )}},model:{value:(item.pivot.hour_employee_pay_wo_vat),callback:function ($$v) {_vm.$set(item.pivot, "hour_employee_pay_wo_vat", _vm._n($$v))},expression:"item.pivot.hour_employee_pay_wo_vat"}})]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('BaseActionMenu',{attrs:{"actions":_vm.actions,"item":item}})]}},{key:"expanded-item",fn:function(ref){
        var item = ref.item;
        var index = ref.index;
return [_c('BaseExpandedTableRow',{attrs:{"colspan":_vm.tableMixin_displayedHeaders.length,"headers":_vm.tableMixin_hiddenHeaders,"item":item},scopedSlots:_vm._u([{key:"item.pivot.hour_price_wo_vat",fn:function(){return [_c('BaseEditableField',{attrs:{"has-error":!item.pivot.hour_price_wo_vat && item.pivot.hour_price_wo_vat !== 0,"type":"number"},on:{"input":function($event){return _vm.$emit('clear:errors', ("processes." + (_vm.itemIndexesMap[item.id]) + ".pivot.hour_price_wo_vat"))}},model:{value:(item.pivot.hour_price_wo_vat),callback:function ($$v) {_vm.$set(item.pivot, "hour_price_wo_vat", _vm._n($$v))},expression:"item.pivot.hour_price_wo_vat"}})]},proxy:true},{key:"item.pivot.hour_employee_pay_wo_vat",fn:function(){return [_c('BaseEditableField',{attrs:{"has-error":!item.pivot.hour_price_wo_vat && item.pivot.hour_employee_pay_wo_vat !== 0,"type":"number"},on:{"input":function($event){return _vm.$emit(
              'clear:errors',
              ("processes." + (_vm.itemIndexesMap[item.id]) + ".pivot.hour_employee_pay_wo_vat")
            )}},model:{value:(item.pivot.hour_employee_pay_wo_vat),callback:function ($$v) {_vm.$set(item.pivot, "hour_employee_pay_wo_vat", _vm._n($$v))},expression:"item.pivot.hour_employee_pay_wo_vat"}})]},proxy:true}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }